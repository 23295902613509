import { loadLocalStorage } from "@/utils/localStorage";

export default (event, data = {}) => {
  const { origin } = loadLocalStorage();

  if (!window.parent || !origin) return;

  const message = JSON.stringify({
    event,
    data,
    wealthica: true,
  });

  window.parent.postMessage(message, origin);
};
