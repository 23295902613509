<template>
  <div class="widget-wrapper">
    <Connected v-if="$route.name === 'connected'" />
    <Exit v-else-if="$route.name === 'exit'" />
    <ConnectInstitution
      v-else-if="connectionStep === STEP_CONNECT_INSTITUTION"
      :error="error"
      :validated="validated"
    />
    <SelectProvider v-else-if="connectionStep === STEP_SELECT_PROVIDER" />
    <ProvideCredentials v-else-if="connectionStep === STEP_PROVIDE_CREDENTIALS" />
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import jwt from "jsonwebtoken";
import SelectProvider from "@/components/SelectProvider.vue";
import ConnectInstitution from "@/components/ConnectInstitution.vue";
import ProvideCredentials from "@/components/ProvideCredentials.vue";
import Connected from "@/components/Connected.vue";
import Exit from "@/components/Exit.vue";
import {
  STEP_CONNECT_INSTITUTION,
  STEP_SELECT_PROVIDER,
  STEP_PROVIDE_CREDENTIALS,
  MAX_TOKEN_LIFETIME,
} from "@/constants";
import API from "@/api";
import redirection from "@/mixins/redirection";
import isValidOrigin from "@/utils/isValidOrigin";
import isValidRedirectUri from "@/utils/isValidRedirectUri";
import providerInfo from "@/mixins/providerInfo";
import sendEventToParent from "@/utils/sendEventToParent";

function isExpired(payload) {
  // Reject if token is older than 10 minutes
  return moment().diff(payload.iat * 1000, "seconds") > MAX_TOKEN_LIFETIME;
}

export default {
  components: { SelectProvider, ConnectInstitution, ProvideCredentials, Connected, Exit },
  mixins: [redirection, providerInfo],
  data() {
    return {
      STEP_CONNECT_INSTITUTION,
      STEP_SELECT_PROVIDER,
      STEP_PROVIDE_CREDENTIALS,
      error: null,
      validated: false,
    };
  },
  computed: {
    ...mapGetters("app", [
      "connectionStep",
      "clientId",
      "clientState",
      "isBackFromOAuth",
      "token",
      "redirectURI",
      "origin",
      "lang",
      "theme",
    ]),
    ...mapGetters("team", ["team", "redirectURIs", "companyName"]),
  },
  watch: {
    $route(to, from) {
      if (from.name === "connect-provider" && to.name === "connect") {
        this.goToSelectProviderStep();
      }
      if (from.name === "connect" && to.name === "connect-provider") {
        this.goToProvideCredentialsStep();
      }
    },
  },
  // eslint-disable-next-line consistent-return
  async created() {
    const { name, query } = this.$route;

    const isBackFromOAuth = query.state && (query.code || query.error);
    if (isBackFromOAuth) this.setIsBackFromOAuth(isBackFromOAuth);

    if (name === "404") {
      sendEventToParent("ERROR", { type: "PAGE_NOT_FOUND" });
      return this.redirectWithError(400, this.$t("page_not_found"));
    }

    this.validated = await this.validateAndSetConnectionInfo();

    if (this.validated && this.isBackFromOAuth) this.goToProvideCredentialsStep();
  },
  methods: {
    ...mapActions("app", [
      "goToSelectProviderStep",
      "goToProvideCredentialsStep",
      "setIsBackFromOAuth",
      "setPreselected",
      "setToken",
      "setClientId",
      "setClientState",
      "setRedirectURI",
      "setOrigin",
      "updateLanguage",
      "updateTheme",
      "updateFeatures",
      "updateProvidersPerLine",
      "updateProviderGroups",
      "setSelectedProvider",
      "fireEvent",
    ]),
    ...mapActions("team", ["fetchTeam"]),
    ...mapActions("institutions", ["fetchInstitution", "fetchProviders"]),
    async validateAndSetConnectionInfo() {
      const { query, name: routeName, params: routeParams } = this.$route;
      const params = this.isBackFromOAuth
        ? {
            // when isBackFromOAuth, store should already load this props from localStorage
            client_id: this.clientId,
            token: this.token,
            redirect_uri: this.redirectURI,
            origin: this.origin,
            lang: this.lang,
            theme: this.theme,
            features: this.features,
          }
        : {
            ...query,
            // token query param used in mobile applications setup
            // first of all try to use token from GET param to avoid being overwritten with old token stored in localstorage if it's not cleaned after session for some reason
            token: query.token || this.token,
          };

      const redirectURI = params.redirect_uri;
      let payload;

      // Storing lang to pass back to redirect_uri later.
      if (params.lang) this.updateLanguage(params.lang);

      // Store theme
      if (params.theme) this.updateTheme(params.theme);

      // Store feature flags
      if (params.features) this.updateFeatures(params.features);

      // Store providers_per_line
      if (params.provider_groups) this.updateProviderGroups(params.provider_groups);

      // Fetch providers right after applying theme to not delay applying theme
      await this.fetchProviders(params.client_id);
      if (["connect-provider", "reconnect-institution"].includes(routeName))
        this.setPreselected(true);

      // Store providers_per_line
      if (params.providers_per_line) this.updateProvidersPerLine(params.providers_per_line);

      // Process oAuth Error Response
      if (this.isBackFromOAuth && query.error) {
        sendEventToParent("ERROR", { type: "OAUTH_ERROR" });
        this.redirectWithError(400, this.$t("oauth_error", { prop: query.error }));
        return false;
      }

      // If not isBackFromOAuth, state would be the client's state.
      // Storing client state to pass back to redirect_uri later.
      if (params.state && !this.isBackFromOAuth) {
        localStorage.setItem("client_state", params.state);
        this.setClientState(params.state);
      }

      if (params.client_id) {
        localStorage.setItem("client_id", params.client_id);
        this.setClientId(params.client_id);
      } else {
        sendEventToParent("ERROR", { type: "CLIENT_ID_REQUIRED" });
        this.redirectWithError(400, this.$t("prop_required", { prop: "client_id" }));
        return false;
      }

      if (params.origin) {
        let { origin } = params;

        if (typeof origin !== "string") {
          sendEventToParent("ERROR", { type: "INVALID_ORIGIN" });
          this.redirectWithError(400, this.$t("wrong_prop", { prop: "origin" }));
          return false;
        }

        // Fallback protocol if not provided in origin. For backward compatibility with older versions of
        // the SDK (< 0.0.15) where origin only includes hostname.
        if (!origin.includes("://") && origin !== "*") {
          origin = `${window.location.protocol}//${origin}`;
        }

        if (!isValidOrigin(origin)) {
          sendEventToParent("ERROR", { type: "INVALID_ORIGIN" });
          this.redirectWithError(400, this.$t("wrong_prop", { prop: "origin" }));
          return false;
        }

        localStorage.setItem("origin", origin);
        this.setOrigin(origin);
      }

      if (params.token) {
        payload = jwt.decode(params.token);
        if (!payload) {
          sendEventToParent("ERROR", { type: "INVALID_TOKEN" });
          this.redirectWithError(400, this.$t("invalid_token"));
          return false;
        }

        if (isExpired(payload)) {
          sendEventToParent("ERROR", { type: "SESSION_EXPIRED" });
          this.redirectWithError(401, this.$t("session_expired"));
          return false;
        }

        localStorage.setItem("token", params.token);
        this.setToken(params.token);
      } else {
        sendEventToParent("ERROR", { type: "TOKEN_REQUIRED" });
        this.redirectWithError(400, this.$t("prop_required", { prop: "token" }));
        return false;
      }

      // NOTE token must be validated and cached to store before .fetchTeam(), because the request
      // use token as Authorization header
      try {
        await this.fetchTeam(params.client_id);

        if (!this.team) {
          sendEventToParent("ERROR", { type: "WRONG_CLIENT_ID" });
          this.redirectWithError(400, this.$t("wrong_prop", { prop: "client_id" }));
          return false;
        }

        document.documentElement.style.setProperty(
          "--primary-color",
          this.team.primary_color || "#7C2DD4"
        );

        document.documentElement.style.setProperty(
          "--font",
          this.team.font || "Inter, Helvetica Neue, Arial, Helvetica, sans-serif"
        );

        // Set page title after getting team
        document.title = `${this.companyName} Connect`;
      } catch (e) {
        sendEventToParent("ERROR", { type: "WRONG_CLIENT_ID" });
        this.redirectWithError(400, this.$t("wrong_prop", { prop: "client_id" }));
        return false;
      }

      if (redirectURI) {
        if (isValidRedirectUri(redirectURI, this.redirectURIs)) {
          localStorage.setItem("redirect_uri", redirectURI);
          this.setRedirectURI(redirectURI);
        } else {
          sendEventToParent("ERROR", { type: "WRONG_REDIRECT_URI" });
          this.redirectWithError(400, this.$t("wrong_prop", { prop: "redirect_uri" }));
          return false;
        }
      }

      // When all is good, update API session timeout monitor
      // First cleanup any possible old monitors that might be pointing to the old redirectURI
      API.monitors.length = 0;

      // Add new monitor that redirects to redirect_uri when we get a 401 from Wealthica API
      // and the token has expired
      API.addMonitor(response => {
        if (response.status !== 401) return;

        sendEventToParent("ERROR", {
          type: isExpired(payload) ? "SESSION_EXPIRED" : "INVALID_TOKEN",
        });
        this.redirectWithError(
          401,
          isExpired(payload) ? this.$t("session_expired") : this.$t("invalid_token")
        );
      });

      // (Reconnect only) Now with token verified, verify institution id
      if (routeName === "reconnect-institution") {
        const response = await this.fetchInstitution(routeParams.institution);
        if (!response._id) {
          sendEventToParent("ERROR", { type: "INVALID_INSTITUTION_ID" });
          this.redirectWithError(400, this.$t("invalid_institution"));
          return false;
        }

        if (response.sync_task && response.sync_lock && moment().isBefore(response.sync_lock)) {
          sendEventToParent("ERROR", { type: "RECONNECT_CONFLICT" });
          this.redirectWithError(400, this.$t("reconnect_conflict"));
          return false;
        }
      }

      // todo: completely move selected provider to store from mixin
      if (this.providerName) {
        this.setSelectedProvider(this.provider);
      }

      sendEventToParent("APP_LOADED");
      this.fireEvent({ name: "connect flow - started" });

      return true;
    },
  },
};
</script>

<style lang="scss">
.widget-wrapper {
  height: inherit;
  display: flex;
  flex-direction: column;
}

.home {
  background-color: white;
  padding: 20px 0;
}
</style>
