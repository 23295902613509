<template>
  <div class="wrap">
    <HeaderTop hide-back />
    <div class="connected">
      <div class="connected__logo-wrap">
        <div class="connected__logo">
          <img v-if="team && team.logo" :src="team.round_logo || team.logo" alt="" />
          <div v-else-if="team" class="connected__logo-letters">
            {{ team.name.charAt(0) }}
          </div>
        </div>
        <LottieAnimation class="connected__icon" name="success" :loop="false" />
        <div class="connected__logo">
          <img :src="iconUrl(provider)" :alt="provider.name" />
        </div>
      </div>
      <div class="connected__title">{{ $t("success") }}</div>
      <div class="connected__text">{{ $t("your_institution_is_successfully_connected") }}</div>
    </div>
  </div>
</template>

<script>
import LottieAnimation from "@/components/ui/LottieAnimation.vue";
import sendDataToParent from "@/utils/sendDataToParent";
import sendEventToParent from "@/utils/sendEventToParent";
import { cleanupLocalStorage } from "@/utils/localStorage";
import composeUrl from "@/utils/composeUrl";
import { mapGetters } from "vuex";
import HeaderTop from "@/components/ui/HeaderTop.vue";
import providerInfo from "@/mixins/providerInfo";
import { WEALTHICA_APP_URL } from "@/constants";

export default {
  components: {
    HeaderTop,
    LottieAnimation,
  },
  mixins: [providerInfo],
  computed: {
    ...mapGetters("app", ["redirectURI"]),
    ...mapGetters("team", ["team"]),
  },
  created() {
    sendEventToParent("SCREEN_LOADED", { screen: "CONNECTION_SUCCESS" });
    // wait some time (2 seconds) before close widget or redirect
    this.timeout = setTimeout(this.next, 2000);
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
  methods: {
    next() {
      return this.redirectURI ? this.onRedirectMode() : this.onWidgetMode();
    },
    onRedirectMode() {
      cleanupLocalStorage();
      window.location.href = composeUrl(this.redirectURI, { ...this.$route.query });
    },
    onWidgetMode() {
      sendDataToParent({
        wealthica: true,
        event: "success",
        institution: this.$route.query.institution,
        institution_type: this.provider?.type,
        provider: this.provider?.type,
      });
    },
    iconUrl(provider) {
      return `${WEALTHICA_APP_URL}/images/institutions/${provider.type}.png`;
    },
  },
};
</script>

<style lang="scss">
.connected {
  max-width: 252px;
  margin: auto;
  margin-top: 120px;
  &__title {
    @include title-1;

    margin-top: 30px;
    margin-bottom: 10px;
    text-transform: capitalize;
  }

  &__text {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: $gray-800;
  }

  &__logo-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 1px #fff;
    color: var(--primary-color);

    img {
      width: 100%;
    }
  }

  &__logo-letters {
    width: 100%;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    line-height: 51px;
    background-color: #000;
  }

  &__icon {
    width: 87px;
    height: 87px;
    margin: 0 28px;
  }
}
</style>

<style lang="scss">
.theme-dark {
  .connected {
    &__text {
      color: $gray-450;
    }
  }
}
</style>
