import { mapActions, mapGetters } from "vuex";
import redirection from "@/mixins/redirection";
import _ from "lodash";
import sendEventToParent from "@/utils/sendEventToParent";

export default {
  mixins: [redirection],
  data() {
    return {
      pollReplayed: 0,
      securityAnswer: "",
      syncError: null,
    };
  },
  computed: {
    ...mapGetters("app", ["clientState"]),
    ...mapGetters("institutions", ["institution", "providers"]),
    institutionData() {
      const { name, type } = this.provider;

      return {
        name,
        type,
        credentials: this.form,
        _id: this.institution ? this.institution._id : undefined,
      };
    },
  },
  methods: {
    ...mapActions("institutions", [
      "createInstitution",
      "updateInstitution",
      "pollInstitution",
      "resetInstitution",
    ]),
    ...mapActions("app", ["setSecurityAnswerRequested"]),
    onInstitutionSyncError(institution) {
      // Clear error message got from the previous poll.
      this.error = "";

      this.syncError = institution.sync_error;
      const hasQuestion = this.syncError.name === "SecurityQuestionError";
      this.securityAnswer = "";

      this.setSecurityAnswerRequested(hasQuestion);
      if (hasQuestion) {
        sendEventToParent("ERROR", { type: "SECURITY_QUESTION_REQUIRED" });
        this.fireEvent({
          name: "connect flow - 2fa requested",
          institution: institution?._id,
          error: institution?.sync_error?.name,
          error_message: institution?.sync_error?.message,
        });
        return;
      }

      this.setSecurityAnswerRequested(false);

      if (this.syncError.name === "LoginFailedError") {
        sendEventToParent("ERROR", { type: "INVALID_CREDENTIALS" });
        this.fireEvent({
          name: "connect flow - invalid credentials",
          institution: institution?._id,
          error: institution?.sync_error?.name,
          error_message: institution?.sync_error?.message,
        });
        this.onInvalidCredentialsError(institution);
        return;
      }

      if (this.syncError.name === "UserActionRequiredError") {
        sendEventToParent("ERROR", { type: "USER_ACTION_REQUIRED" });
        this.fireEvent({
          name: "connect flow - action required",
          institution: institution?._id,
          error: institution?.sync_error?.name,
          error_message: institution?.sync_error?.message,
        });
        this.onUserActionRequiredError(institution);
        return;
      }

      sendEventToParent("ERROR", { type: "UNKNOWN_ERROR" });
      this.fireEvent({
        name: "connect flow - unnamed error",
        institution: institution?._id,
        error: institution?.sync_error?.name,
        error_message: institution?.sync_error?.message,
      });
      this.goBackWithError(this.syncError.message);
    },
    async onInstitutionError(institution, error) {
      if (institution && institution.sync_status === "error") {
        this.onInstitutionSyncError(institution);
        return;
      }

      if (error.status === 503 || error.problem === "TIMEOUT_ERROR") {
        sendEventToParent("ERROR", { type: "TIMEOUT_ERROR" });
        this.setLoading(true);
        await this.fireEvent({
          name: "connect flow - timeout 503",
          institution: institution?._id,
          ..._.pick(error, ["status", "problem"]),
        });
        this.onInstitutionTimeout(institution);
        return;
      }

      if (error.status >= 500 || error.problem === "CONNECTION_ERROR") {
        sendEventToParent("ERROR", { type: "CONNECTION_ERROR" });
        this.setLoading(true);
        await this.fireEvent({
          name: "connect flow - error 5xx",
          institution: institution?._id,
          ..._.pick(error, ["status", "problem"]),
        });
        this.resetInstitution();
        this.redirectWithError(400, this.$t("connection_error"));
        return;
      }

      if (error.problem === "NETWORK_ERROR") {
        sendEventToParent("ERROR", { type: "NETWORK_ERROR" });
        this.fireEvent({
          name: "connect flow - network error",
          institution: institution?._id,
          ..._.pick(error, ["status", "problem"]),
        });
        // note: should not close app so user can try again
        this.error = this.$t("network_error");
        this.resetInstitution();
        return;
      }

      if (error.status === 404) {
        sendEventToParent("ERROR", { type: "INVALID_CREDENTIALS" });
        this.fireEvent({
          name: "connect flow - invalid credentials 404",
          institution: institution?._id,
        });
        this.onInvalidCredentialsError(institution);
        return;
      }

      if (error.status === 409) {
        sendEventToParent("ERROR", { type: "RECONNECT_CONFLICT" });
        this.setLoading(true);
        await this.fireEvent({
          name: "connect flow - reconnect conflict",
          institution: institution?._id,
        });
        this.resetInstitution();
        this.redirectWithError(400, this.$t("reconnect_conflict"));
        return;
      }

      sendEventToParent("ERROR", { type: "UNKNOWN_ERROR" });
      this.setLoading(true);
      await this.fireEvent({
        name: "connect flow - unknown error",
        institution: institution?._id,
        ..._.pick(error, ["status", "problem"]),
      });
      this.redirectWithError(400, this.$t("unknown_error"));
      this.resetInstitution();
    },
    onInvalidCredentialsError(institution) {
      this.resetInstitution();

      let message = this.$t("we_couldnt_login_to_institution", {
        name: institution.name || this.institutionData?.name,
      });

      if (institution?.sync_error?.message && institution.sync_error.message !== "Login failed") {
        message += ` ${this.$t("original_error")}: "${institution.sync_error.message}"`;
      }

      this.goBackWithError(message);
    },
    onUserActionRequiredError(institution) {
      this.resetInstitution();

      const message = this.$t("your_action_required_at_site", {
        name: institution.name || this.institutionData?.name,
        error: institution.sync_error.message,
      });

      this.goBackWithError(message);
    },
    goBackWithError(error) {
      // Since we do not really "go back", we just set the error and reset the form
      this.error = error;
      this.form = {};
    },
    onInstitutionTimeout(institution) {
      this.redirectWithError(
        400,
        this.$t("connection_timeout", { name: institution.name }),
        false,
        institution
      );

      this.resetInstitution();
    },
    async onRetry(institution) {
      sendEventToParent("ERROR", { type: "RETRY_REQUIRED" });
      this.setLoading(true);
      // wait for fireEvent to complete before redirection, to avoid missed events
      await this.fireEvent({
        name: "connect flow - retry required",
        institution: institution?._id,
        error: institution?.sync_error?.name,
        error_message: institution?.sync_error?.message,
      });
      this.redirectWithError(
        400,
        `${institution.sync_error.message}. ${this.$t("retry")}.`,
        false,
        institution
      );

      this.resetInstitution();
    },
    async onInstitutionSuccess(institution) {
      this.setLoading(true);
      await this.fireEvent({
        name: "connect flow - success",
        institution: institution?._id,
      });
      this.connected = true;

      this.redirectWithInstitution(institution);
    },
    // For details about the version bumps during sync:
    // https://basecamp.com/2854999/projects/8415675/documents/15367318
    async doPoll(institution, expectedV = institution.__v + 2) {
      const result = await this.pollInstitution({
        id: institution._id,
        v: expectedV - 1,
      });

      // Reset syncError only when the poll is complete.
      // syncError should be visible while waiting for the response the next poll
      // to prevent resetting the current Security Answer screen.
      this.syncError = null;

      if (result && !result._id) return this.onInstitutionError(institution, result);

      // We replay the poll up to 7 times - 4 minutes in case of a poll timeout (the server returns only 304
      // status and not any data.
      if (!result) {
        if (this.pollReplayed >= 7) {
          sendEventToParent("ERROR", { type: "POLL_TIMEOUT" });
          this.setLoading(true);
          // wait for fireEvent to complete before calling onInstitutionTimeout, to avoid missed events
          await this.fireEvent({
            name: "connect flow - timeout",
            institution: institution?._id,
            error: institution?.sync_error?.name,
            error_message: institution?.sync_error?.message,
          });
          return this.onInstitutionTimeout(institution);
        }

        this.pollReplayed += 1;
        return this.doPoll(institution, expectedV);
      }

      // If new institution data is returned, check sync_status & show corresponding screen
      switch (result.sync_status) {
        case "error":
          return this.onInstitutionSyncError(result);
        case "retry":
          return this.onRetry(result);
        case "ok":
        case "syncing":
          if (this.hadSecurityAnswer) {
            this.hadSecurityAnswer = false;

            // Do not wait for investments, return right away when authorized to minimize timeout.
            // If the the worker crashes for some reason when adding, at least the end-client will
            // have the institutionId on their end.
            if (result.authorized) return this.onInstitutionSuccess(result);

            // Since we just need to verify authorized, only need to poll for 1 v bump next.
            return this.doPoll(result, result.__v + 1);
          }

          return this.onInstitutionSuccess(result);
        default:
          return null;
      }
    },

    async submitInstitution() {
      sendEventToParent("SUBMIT_CREDENTIALS");
      this.fireEvent({
        name: "connect flow - submit institution",
        institution: this.instittuion?._id,
      });

      this.pollReplayed = 0;

      const hadCredentials = !!this.institutionData.credentials;

      const actionName = this.institution ? "updateInstitution" : "createInstitution";
      const result = await this[actionName](this.institutionData);

      if (!result._id) return this.onInstitutionError(this.institutionData, result);

      return this.updateStateAndPoll({
        institution: result,
        hadCredentials,
        hadSecurityAnswer: false,
      });
    },

    async submitAnswer() {
      sendEventToParent("SUBMIT_ANSWER");
      this.fireEvent({ name: "connect flow - submit answer", institution: this.institution._id });

      this.pollReplayed = 0;

      const data = {
        security_answer: this.securityAnswer,
        _id: this.institution._id,
      };

      const result = await this.updateInstitution(data);

      if (!result._id) return this.onInstitutionError(data, result);

      return this.updateStateAndPoll({
        institution: result,
        hadCredentials: false,
        hadSecurityAnswer: true,
      });
    },

    updateStateAndPoll({ institution, hadSecurityAnswer }) {
      this.hadSecurityAnswer = hadSecurityAnswer;
      this.pollReplayed = 0;

      return this.doPoll(institution);
    },
  },
};
